<template>
  <div class="paymentPlan">
      <div class="paymentPlanCon">
        <ul class="paymentPlanConTr">
          <li class="fistTr">
            <span>序号</span>
            <span>用户账号</span>
            <span>邀请日期</span>
            <span>注册日期</span>
          </li>
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="listComponent.loading"
              :finished="listComponent.finished"
              :error="listComponent.error"
              finished-text="没有更多了"
              error-text="请求失败，点击重新加载"
              @load="loadMore"
            >
              <li class="listItem" v-for="(item,index) in LiatData">
                <span>{{index+1}}</span>
                <span>{{item.mobilePhone}}</span>
                <span>{{item.inviteTime}}</span>
                <span>{{item.inviteTime}}</span>
              </li>
            </van-list>
          </van-pull-refresh>
      
        </ul>
      </div>
    </div>
</template>
<script>

import {getUserInviteListPage} from "../services/api";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      listComponent: {
        loading: true,
        finished: false,
        error: false,
        page: 1
      },
      refreshing:true,
      active:0,
      LiatData: [],
      entryTime: "",
      busy: false,
      page: 1,
      ListFlag: false,
      totalPage: 1,
    };
  },
  created: function() {
    console.log();
    var _this = this;
    _this.onRefresh('','WAP','');
    
  },
  methods: {
   tips(){
      let msg="1、若分配方式为到期一次性分配本金及收益，则产品到期日起三个工作日内一次性分配投资本金及总收益<br />2、若分配方式为按月分配收益，到期分配本金，则在每期分配日支付一个自然月的收益，分配日遇节假日进行相关调整，产品到期日起三个工作日内分配投资本金及剩余收益<br />3、若分配方式为按季分配收益，到期分配本金，则在每期分配日支付三个自然月的收益，分配日遇节假日进行相关调整，产品到期日起三个工作日内分配投资本金及剩余收益<br />4、收益结算日为每一期收益计算截止日期"
      Dialog.alert({
        title: '温馨提示',
        confirmButtonText:'我知道了',
        message:msg,
      })
      .then(() => { })
   },
   async onRefresh() {
      this.LiatData = [];
      this.listComponent.page = 1;
      await this.getData();
    },
   loadMore() {
      this.listComponent.page += 1;
      this.getData();
    },
    async getData( dfmaslkgh, terminal, channelFlag) {//列表接口
      this.listComponent.loading = true;
      const rows = 10;
      const datas = await getUserInviteListPage({
        current: this.listComponent.page,
        pageSize: rows,
        channelFlag: channelFlag, 
        dfmaslkgh: dfmaslkgh,
        terminal: terminal,
        user_id: this.userId
      });
      this.listComponent.loading = false;
      this.refreshing = false;
      if (datas.code === 1) {
        this.products = datas.res_data;
        const list = datas?.data.records;
        if (list?.length) {
          this.LiatData = this.LiatData.concat(datas.data.records);
        }
        this.listComponent.finished = !list || (this.LiatData?.length == datas?.data.total);
      } else {
        this.listComponent.error = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.paymentPlanConTr{
  padding:1rem 0;
  li{
    border-bottom: 0.1rem solid #ddd;line-height:2rem;
    span{width: 25%;display: inline-block;text-align: center;font-size:0.9rem;}
    span:first-child{width: 15%;}
    span:nth-child(2){width: 30%;}
  }
 
  .fistTr{
    padding-bottom:0.5rem;
    font-size:1rem;
    span{font-weight: bold;}
  }
}
</style>
